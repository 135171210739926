export const environment = {
	production: true,
	apiLink: "https://dwm-middleware-release.ischoolconnect.com",
	tokenKey: "SOP_ACCESS_TOKEN",
	cookieDomain: "ischoolconnect.com",
	cdnBaseUrl: "https://release-cdn.ischoolconnect.com",
	studentLogrocketId: "comozl/student-dashboard-stage-b6wdv",
	advisorLogrocketId: "comozl/advisor-dashboard-stage-pcxny",
	advisorBackendUrl: "https://release-advisor-api-uc.ischoolconnect.com"
};
